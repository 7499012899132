.guideFrameHidden {
    visibility: hidden;
}

.guideFrameVisible {
    position: absolute;
    width: 40%;
    height: 25vh;
    background-color: white;

    z-index: 10000000000;

    bottom: 2vh;
    left: 20%;

    box-shadow: 0 0 25px 0 black;

    border-style: solid;

    pointer-events: auto;

    resize: both;
    overflow: hidden;
}

.guideBackground {
    position: absolute;
    top: -15%;
    left: 0;
    bottom: -10%;
    right: 0;
    z-index: 10000000000;

    pointer-events:none;
}

.guideFrameHeader {
    cursor: move;
    width: 100%;
    height: 15%;
    max-height: 40px;
    top: 0;
    background-color: #9dd7ff;

    border-bottom-style: solid;
}

.guideFrameTitleText {
    width: 90%;
    height: 100%;
    text-align: center;
    font-size: 2vh;
}

.guideFrameCloseButton {
    position: absolute;

    height: 15%;
    max-height: 40px;
    width: 10%;

    right: 0;
    top: 0;

    font-size: 2.25vh;

    object-fit: contain;
    background-color: white;

    border-left-style: solid;
    border-right-style: hidden;
    border-top-style: hidden;
}

.guideFrameBody {
    position: absolute;

    height: 85%;
    width: 100%;

    padding: 1%;
}

.guideFrameBodyTextMode {
    height: 100%;
    width: 100%;

    font-size: 2.25vh;

    overflow: auto;
}

.guideVideoIframe {
    height: 100%;
    width: 100%;
}